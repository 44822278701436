<template>
  <div class="mt-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">No. SPP *</label>
        <vs-input class="w-full" :value="data.no_spk || 'Generate Otomatis'" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Jenis SPP *</label>
        <vs-select autocomplete v-model="data.jenis_spk" class="w-full">
          <vs-select-item v-for="(it, index) in listJenisSpk" :key="index" :value="it" :text="it"/>
        </vs-select>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Proyek *</label>
        <vs-select autocomplete v-model="data.id_proyek" class="w-full" @input="onChangeProyek">
          <vs-select-item v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="it.kode + ' - ' + it.nama"/>
        </vs-select>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Rekanan *</label>
        <vx-input-group>
          <vs-input placeholder="Pilih Rekanan" :value="data.nama_rekanan" @click="modalRekanan.active = true" :disabled="!data.id_proyek"/>
          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="modalRekanan.active = true" :disabled="!data.id_proyek"/>
            </div>
          </template>
        </vx-input-group>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl SPP *</label>
        <flat-pickr class="w-full" v-model="data.tgl_spk"></flat-pickr>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl Awal *</label>
        <flat-pickr class="w-full" v-model="data.tgl_awal"></flat-pickr>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Tgl Akhir *</label>
        <flat-pickr class="w-full" v-model="data.tgl_akhir"></flat-pickr>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Masa (Hari) *</label>
        <vs-input class="w-full" :value="masaHari" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Jenis Pekerjaan *</label>
        <vs-input class="w-full" v-model="data.jenis_pekerjaan"/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Files</label>
        <vs-input class="w-full" type="file" multiple accept=".jpeg,.jpg,.png,.pdf" v-model="data.filesTmp" @change="data.files = $event.target.files"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Lingkup Pekerjaan *</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.lingkup_pekerjaan"/>
      </div>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

    <!--modal item rekanan-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih Data Rekanan"
              :active="modalRekanan.active"
              v-on:update:active="modalRekanan.active = $event">
      <Rekanan :selectable="true"
               :externalFilter="filterRekanan"
               @selected="onSelectedModalRekanan"/>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import Rekanan from '@/views/pages/master/rekanan/Rekanan'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import ProyekRepository from '@/repositories/master/proyek-repository'
import moment from 'moment'
import constant from '@/constant/constant'

export default {
  name: 'TabHeader',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    Rekanan,
    ValidationErrors,
    flatPickr
  },
  mounted () {
    this.commitDataToStore(this.data)
    this.getAllProyek()
  },
  computed: {
    errors () {
      return this.$store.state.proyek.spkAdd.errors.tabHeader.messages
    },
    filterRekanan () {
      return this.data.id_proyek ? { id_proyek: this.data.id_proyek } : null
    },
    masaHari () {
      const start = moment(this.data.tgl_awal, 'YYYY-MM-DD')
      const end = moment(this.data.tgl_akhir, 'YYYY-MM-DD')
      return (end.diff(start, 'days') + 1) || ''
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      proyeks: [],
      listJenisSpk: [constant.JENIS_SPK.UNIT, constant.JENIS_SPK.NON_UNIT, constant.JENIS_SPK.OTHER],
      modalRekanan: {
        active: false
      },
      data: {
        jenis_spk: constant.JENIS_SPK.UNIT,
        id_proyek: null,
        tgl_spk: moment().format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    onSelectedModalRekanan (item) {
      this.data = {
        ...this.data,
        id_rekanan: item.id,
        nama_rekanan: item.nama
      }
      this.modalRekanan.active = false
    },

    onChangeProyek (value) {
      this.data.id_rekanan = null
      this.data.nama_rekanan = null
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep({
        ...value,
        masa: this.masaHari
      })
      this.$store.commit('proyek/spkAdd/SET_TAB_HEADER', payload)
    }, 500),

    next () {
      this.$store.commit('proyek/spkAdd/SET_ACTIVE_TAB', 1)
    }
  }
}
</script>
